.g_header {
  box-sizing: border-box;

  height: 80px;

  background: rgba(255, 255, 255, 0.93);
  border-bottom: 1px solid #f7f7f7;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  padding: 0 24px;
  justify-content: space-between;
}
.g_footer {
  height: 100px;

  /* © 2024 XXXXX.com */

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;

  color: #000000;
}
.g_footer ul {
  display: flex;
  align-items: center;
  gap: 32px;
}
.logo {
  /* LOGO */

  width: 246px;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height */

  color: #000000;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}
.nav {
  /* Frame 4 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;
}
.nav a {
  display: flex;

  height: 37px;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;

  color: #555555;
  text-decoration: none;
  padding: 6px 18px;
  transition: all 0.3s ease-in-out;
}
.nav a:hover,
.nav a.on {
  /* Frame 19 */

  background: #75de44;
  border-radius: 40px;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;

  color: #000000;
  padding: 6px 17px;
}
.lang {
  /* Frame 22 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 6px 8px;
  gap: 8px;
  cursor: pointer;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  text-align: center;

  color: #555555;

  position: relative;
}
.lang_pop {
  position: absolute;
  top: 36px;
  right: 0;
  /* Frame 57 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 28px 20px;
  gap: 15px;

  width: 167px;
  height: 0px;

  background: rgba(255, 255, 255, 0.93);
  border: 1px solid #ececec;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(8px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;
}
.lang_pop.open {
  height: 161px;
  opacity: 1;
  visibility: visible;
}
.lang_pop li {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  text-align: left;
  white-space: nowrap;
}
.lang_pop li.on,
.lang_pop li:hover {
  color: #75de44;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.banner {
  min-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 80px 0 128px;
  justify-content: center;
  align-items: center;
  background: #fefefe;
}
.banner img {
  width: 1200px;
}
.banner h1 {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  text-align: center;

  color: #000000;
  position: relative;
}
.banner h1 span {
  width: 190px;
  height: 12px;
  background: #75de44;
  position: absolute;
  display: block;
  z-index: 1;
}
.banner h1 p {
  position: relative;
  z-index: 10;
}

.banner2 {
  height: 900px;

  width: 100%;
  padding: 100px 0 0;
  background: #f4f9ff;
}
.banner2 h2 {
  text-align: center;
  /* 一站式解决出海企业营销难题 */

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  text-align: center;

  color: #000000;
}
.banner2 span {
  /* Frame 7 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 12px;

  position: absolute;

  background: #75de44;
  box-shadow: 0px 4px 16px rgba(52, 180, 194, 0.2);
  border-radius: 40px;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  /* identical to box height */
  text-align: center;

  color: #000000;
}
.banner2 div {
  position: relative;
  width: 1200px;
  height: 621px;
  margin: 52px auto 0;
  background: url("@/assets/index.ad.png") #f4f9ff no-repeat center;
  background-size: cover;
}
.banner2 span:nth-child(8n) {
  left: 265px;
  top: 25px;
}
.banner2 span:nth-child(8n + 1) {
  left: 551px;
  top: 93px;
}
.banner2 span:nth-child(8n + 2) {
  left: 870px;
  top: 68px;
}
.banner2 span:nth-child(8n + 3) {
  left: 119px;
  top: 229px;
}
.banner2 span:nth-child(8n + 4) {
  left: 674px;
  top: 235px;
}
.banner2 span:nth-child(8n + 5) {
  left: 257px;
  top: 389px;
}
.banner2 span:nth-child(8n + 6) {
  left: 572px;
  top: 421px;
}
.banner2 span:nth-child(8n + 7) {
  left: 1000px;
  top: 420px;
}
.banner3 {
  min-width: 1200px;
  margin: 0 auto;
  padding: 160px 0 120px;
}
.banner3 h2 {
  text-align: center;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  text-align: center;

  color: #000000;
  margin: 0 0 64px;
}
.banner3con {
  /* Frame 26 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 1200px;
  height: 460px;
  margin: 0 auto;
}
.banner3con div {
  /* Frame 27 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 62px 16px;
  gap: 23px;

  width: 276px;
  height: 460px;

  background: #e6f0f9;
  border-radius: 230px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
}
.banner3con div span {
  /* Group 1 */

  width: 65px;
  height: 65px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.banner3con div strong {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height */
  text-align: center;

  color: #000000;
}
.banner3con div p {
  /* 搜索引擎优化工具，学会通过谷歌搜索引擎规则，提升网站自然排名流量数据 */

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  /* or 26px */
  text-align: center;

  color: #333333;
}
.banner4 {
  /* Frame 19 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 160px 395px 128px;
  gap: 64px;

  height: 598px;

  background: #fff9ed;
}
.banner4 h2 {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  text-align: center;

  color: #000000;
}
.banner4con {
  /* Frame 26 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 1200px;
  height: 179px;
}
.banner4con div {
  /* Frame 23 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px;
  gap: 16px;

  width: 378.67px;
  height: 179px;

  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}
.banner4con div strong {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #000000;
}

.g-banner {
  /* Frame 19 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0px 128px;
  gap: 64px;
}
.g-banner h2 {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  text-align: center;

  color: #000000;
}
.banner5 {
  background: #f9faff;
}
.adscon {
  /* Frame 31 */

  width: 1200px;
  height: 506px;

  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
}
.adscon1 {
  /* Frame 32 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  height: 388px;
}
.adscon1 span {
  /* Frame 31 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 17px;

  height: 79px;

  background: #fcfffb;
  border: 1px solid #ddead9;
  border-radius: 76px;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 31px;
  /* identical to box height */

  color: #000000;

  cursor: pointer;

  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.adscon1 span.on,
.adscon1 span:hover {
  background: #000000;

  color: #ffffff;
}
.adscon2 {
  width: 380px;
}
.adscon2 img {
  width: 100%;
}
.adscon3 {
  /* Frame 30 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 32px;
  gap: 17px;

  width: 300px;
  height: 330px;

  border: 1px solid #dbddeb;
  border-radius: 16px;
}
.adscon3 h3 {
  /* Frame 30 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 236px;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}
.adscon3 p {
  /* Frame 35 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 236px;
  height: 25px;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;

  color: #494949;
}
.banner6 {
  height: 1116px;
  position: relative;
  overflow: hidden;
}
.banner6bg {
  position: absolute;
  width: 1870px;
  height: 1870px;
  left: calc(50% - 1870px / 2 + 1px);
  top: 320px;

  background: #f9faff;
  border-radius: 2000px;
  z-index: 1;
}
.banner6con {
  position: relative;
  z-index: 2;
  width: 1200px;
  height: 400px;
  margin: 0 auto;
}
.banner6con div {
  /* Frame 49 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 24px;
  position: absolute;
  width: 270px;
}
.banner6con div span {
  /* Group 18 */

  width: 168px;
  height: 168px;
  /* flex-basis: 168px; */
  /* Ellipse 4 */

  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 200px;

  background: #ffffff;
  border: 1px solid #f9faff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
}
.banner6con div strong {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;
  color: #000000;
}
.banner6con div p {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  text-align: center;

  color: rgba(0, 0, 0, 0.9);
}
.banner6con div:nth-child(6n) {
  left: 150px;
  top: 94px;
}
.banner6con div:nth-child(6n + 1) {
  left: -99px;
  top: 237px;
}
.banner6con div:nth-child(6n + 2) {
  left: 459px;
  top: 527px;
}
.banner6con div:nth-child(6n + 3) {
  left: 1040px;
  top: 237px;
}
.banner6con div:nth-child(6n + 4) {
  left: 779px;
  top: 87px;
}
.banner6con div:nth-child(6n + 5) {
  left: 459px;
  top: 27px;
}
.banner7 {
  background: url("@/assets/about.webp") no-repeat center top;
  background-size: auto 894px;
  height: 1080px;
}
.banner7-1 {
  width: 1440px;
  margin: 0 auto 0px;
  padding: 200px 0 0;
  height: 710px;
}
.banner7-1 h1 {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  width: 560px;
  color: #000000;
  margin: 0 0 36px;
}
@media screen and (max-width: 1440px) {
  .banner7-1 {
    width: 1360px;
  }
}
.banner7-1 p {
  /* 通过定制化的营销策略和跨文化业务拓展，帮助中国企业在全球市场建立强大的品牌形象，实现持续增长。我们专注于从市场分析到全球化运营的全方位支持，助力企业在海外市场取得成功。 */

  width: 560px;

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 180%;
  /* or 36px */

  color: #000000;
  word-break: break-word;
}
.banner7-2 {
  height: 300px;
}
.banner7-2 h2 {
  /* 公司介绍 */

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  text-align: center;

  color: #000000;
  margin: 0 auto 48px;
}

.banner7-2 p {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 150%;
  /* or 36px */
  text-align: center;

  color: #000000;
  max-width: 1160px;
  margin: 0 auto;
}
.banner8 {
  padding: 80px 0 0;
}
.banner8 h2 {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  text-align: center;

  color: #000000;
  margin: 0 0 64px;
}
.banner8con {
  /* Frame 53 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;

  width: 1200px;
  height: 310px;
  margin: 0 auto 128px;
}
.banner8con div {
  /* Frame 51 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 16px;
  gap: 16px;

  width: 344px;
  height: 310px;
  min-height: 310px;

  background: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.banner8con div strong {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #000000;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}
.banner8con div p {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* or 21px */
  text-align: center;

  color: rgba(0, 0, 0, 0.9);

  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}
.banner9 {
  /* Frame 6 */

  height: 900px;

  background: #f4f9ff;
  box-shadow: inset 0px -6px 24px rgba(0, 0, 0, 0.03);

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  position: relative;
  padding: 100px 0 0;
  overflow: hidden;
}
.banner9 h2 {
  /* 专注海外社交媒体运营 */

  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  text-align: center;

  color: #000000;
}
.banner9con {
  width: 684px;
  height: 684px;
  position: absolute;
  left: 50%;
  bottom: -100px;
  background: url("@/assets/earth.png") no-repeat -30px -20px;
  background-size: 110% 90%;
  transform: translate(-50%, 0);

  border: 3px solid #d3e3f6;
  border-radius: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner9con strong {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 45px;
  text-align: center;

  color: #000000;
  width: 420px;
}
.banner9con span {
  position: absolute;

  position: absolute;
  width: 78px;
  height: 78px;
  left: 566px;
  top: 549px;
  border-radius: 78px;

  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner9con span:nth-child(7n) {
  left: 600px;
  top: 120px;
}
.banner9con span:nth-child(7n + 1) {
  left: 650px;
  top: 280px;
}
.banner9con span:nth-child(7n + 2) {
  left: -40px;
  top: 280px;
}
.banner9con span:nth-child(7n + 3) {
  left: 10px;
  top: 120px;
}
.banner9con span:nth-child(7n + 4) {
  left: 140px;
  top: -0px;
}
.banner9con span:nth-child(7n + 5) {
  left: 300px;
  top: -40px;
}
.banner9con span:nth-child(7n + 6) {
  left: 450px;
  top: -0px;
}

.banner10 {
  /* Frame 18 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 160px 395px 128px;
  gap: 64px;

  height: 801px;

  background: #ffffff;
}
.banner10 h2 {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;
  text-align: center;

  color: #000000;
}
.banner10con {
  /* Frame 26 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0px;
  gap: 24px;

  width: 1200px;
  height: 382px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}
.banner10con div {
  /* Frame 25 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 16px;
  gap: 16px;

  width: 280px;
  height: 179px;

  background: #e6f0f9;
  border-radius: 16px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}
.banner10con div strong {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  /* identical to box height */
  text-align: center;

  color: #000000;
}
.banner10con div span {
}
